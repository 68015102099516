@import '../../styles.variables.scss';

.accordion {
  &__tab {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.75rem;

    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $blue-noon-10;
    }

    &_active {
      background-color: $highlight-color;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__arrow {
    margin-right: 0.5rem;
  }

  &__content {
    // Add top-bottom margin to all the children
    // so we don't need to add additional class
    & > * {
      margin: 0.25rem 0;
    }
  }

  &__content_with-title {
    padding: 0 0.75rem 0.75rem;
  }

  &__content-grid {
    gap: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > * {
      margin: 0;
    }
  }
}
