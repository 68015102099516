.internal-icon {
  height: 1.125em;
  display: flex;

  &_large {
    height: 1.5em;
  }

  &_pointer {
    cursor: pointer;
  }

  &__component {
    color: inherit;

    svg {
      fill: currentColor;
      height: inherit;
    }
  }
}
