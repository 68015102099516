@import '../../styles.variables.scss';

.documentData {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.documentDataEntry {
  margin: 0 0.5rem 1rem 0.5rem;

  &__title {
    color: $gray-50;
    padding: 0.1rem 0;
  }
}
