@import '~@wartsila/ui-kit/scss/variables';

.documents {
  padding-bottom: 0.5rem;

  &__header-container {
    display: flex;
    align-items: flex-start;
    padding: 0.15rem 0 0.75rem 0;
    justify-content: space-between;

    position: relative;
    z-index: 2;
  }

  &__title-buttons {
    display: flex;
    gap: 0.5rem 0.5rem;
    flex-wrap: wrap;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__header-select {
    width: 200px;
    margin-left: 2rem;
  }

  &__empty {
    padding: 1rem;
    &__button {
      white-space: nowrap;
      padding-top: 1rem;
      width: 50%;
      margin: 0 auto;
    }
  }

  &__frame {
    margin: 0.5rem ; 
    padding: 1;
    flex-grow: 1; 
    height: 50vh;
    background-color: white;
  }

  &__title {
    color: inherit;
    font-size: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  &__children {
    position: relative;
    padding: 1rem 0 0.25rem 1rem;
  }

  &__children > div {
    overflow: visible;
  }

  &__children > button {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  }

  &__loader {
    color: var(--color-text);
  }
}

.document {
  min-height: 450px;
  background-color: white;
  border-radius: 0 0 2px 2px;

  font-size: 0.9rem;
  padding: 0 1rem 1rem 1rem;

  &__cell {
    position: relative;
  }

  &__cell-icon {
    top: 22px;
    left: -8px;
    width: 1rem;
    height: 1rem;
    position: absolute;
  }

  &__cell-icon-linked-bulletins {
    top: 22px;
    left: -22px;
    width: 1rem;
    height: 1rem;
    position: absolute;
  }

  &_warning {
    box-shadow: inset 3px 0 0 #ff7321;
  }

  &__cell-title {
    color: $gray-50;
    padding: 0.25rem 0;
  }

  &__cell-content {
    max-height: 30px;
    overflow-y: auto;
  }
}

// Documents tree border
.rdt_TableRow {
  position: relative;
  z-index: 1;

  &:before {
    top: 50%;
    left: -8px;
    width: 8px;
    content: '';
    height: 1px;
    line-height: 0;
    position: absolute;
    background-color: var(--color-tree-line);
  }

  &:after {
    left: -8px;
    width: 1px;
    z-index: -1;
    content: '';
    position: absolute;
    top: calc(-50% - 36px);
    height: calc(100% + 36px);
    border-left: 1px solid var(--color-tree-line);
  }
}

.rdt_ExpanderRow:not(:last-child) {
  &:after {
    top: -70px;
    left: -8px;
    content: '';
    height: calc(100% + 70px);
    position: absolute;
    border-left: 1px solid var(--color-tree-line);
  }
}
