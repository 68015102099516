@import '../../styles.variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--color-text);

  &__meta {
    display: flex;
    align-items: center;
  }

  &__logo {
    background-color: $white;
    padding: 1rem 68px 1rem 1rem;
    clip-path: polygon(0 0, 100% 0, 72% 100%, 0% 100%);
  }

  &__title {
    font-weight: 300;
    font-size: 1.875rem;
    text-transform: capitalize;

    padding: 0 0.5rem;
  }

  &__nav {
    padding: 0 1rem;
  }
}
