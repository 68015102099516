@import '~@wartsila/ui-kit/scss/variables';

.techrequest__error {
  color: #fff;
  padding: 1rem;
  background-color: $error-color;
}

.techrequest__form {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  input {
    outline: none;
    padding: 0.75rem;
    border-radius: 0;
    min-width: 300px;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
    border: 1px solid $gray-80;
  }

  input:focus {
    border-color: transparent;
    outline: 2px solid $blue-noon;
  }

  button {
    margin-right: 0.75rem;
  }
}

.techrequest_paragraph { 
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}