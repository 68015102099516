@import '~@wartsila/ui-kit/scss/variables';

$info-icon-blue: #086795;

.filters {
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: 2px;

  &__filter {
    padding: 0.15rem 0.5rem 0.5rem;
  }

  &__textarea {
    margin-bottom: 0.5rem;
  }
}

.date-range {
  &-selector-overrides {
    & > div:nth-child(2) {
      right: auto;
    }

    & > div:last-child::after {
      display: none;
    }
  }

  &__reset {
    cursor: pointer;
    font-size: 0.825rem;
    text-decoration: underline;
    color: $blue-noon;
  }

  &__reset:hover {
    text-decoration: none;
  }
}

.react-toggle-container {
  display: flex;
  font-size: 1rem;
  align-items: center;
  padding: 0.5rem 0;

  & > span {
    margin-left: 8px;
  }
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  user-select: none;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 40px;
  height: 22px;
  padding: 0;
  border-radius: 2px;
  transition: all 0.2s ease;
  background-color: $gray-40;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $gray-50;
}

.react-toggle--checked .react-toggle-track {
  background-color: $orange;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $orange;
}

.react-toggle-track-check {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 4px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;

  line-height: 0;
  right: 5px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 17px;
  height: 18px;

  border-radius: 2px;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 21px;
}

.date-alert {
  color: red;
  font-size: x-small;
}

.button-wrapper {
  padding-top: 10px;
}

.advanced-filter-checkboxes {
  padding-top: 10px;
}

.enhanced-node-label {
  display: flex;
}

.info-icon {
  width: 1rem;
  height: 1rem;
  &-margin {
    margin-left: 3px;
  }
}

.info-icon-svg {
  fill: $info-icon-blue;
}
