@import '../../styles.variables.scss';


.update-note-header {
    margin: 0rem 0.5rem 1rem 1.5rem;
  
    &__title {
      font-size: 1.1rem;
      color: $gray-50;
    }
  }

.document {
  min-height: 450px;
  background-color: white;
  border-radius: 0 0 2px 2px;

  font-size: 0.9rem;
  padding: 0 1rem 1rem 1rem;

  &__cell {
    position: relative;
  }

  &_warning {
    box-shadow: inset 3px 0 0 #ff7321;
  }

  &__cell-title {
    color: $gray-50;
    padding: 0.25rem 0;
  }

  &__cell-content {
    max-height: 30px;
    overflow-y: auto;
  }
}