.label {
  width: 100%;
  display: inline-block;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    line-height: 200%;
    font-size: 0.875rem;
  }

  &__title_small {
    line-height: 175%;
    font-size: 0.75rem;
  }
}
