// Redefine dependency styles to match our design

.rct-checkbox {
  display: none;
}

.rct-text {
  display: flex;
  user-select: none;

  padding: 0.125rem 0;

  & button {
    padding-top: 4px;
    padding-left: 2px;
  }

  & label {
    display: flex;
    align-items: center;

    &:hover,
    &:active {
      background-color: transparent;
    }
  }
}

.rct-title {
  padding: 0.1rem 0.25rem 0 0.5rem;
}

.rct-collapse-btn {
  order: 2;
}

.rct-node-leaf {
  & .rct-collapse {
    display: none;
  }
}

.react-checkbox-tree.rct-native-display input {
  margin: 0 0.125rem;
  width: 1.2em;
  height: 1.2em;

  transform: scale(1.1);
}
