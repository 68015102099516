.reftype-doctype-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the container takes the full width */
}

.flex-item {
  flex: 1; /* Allow the child elements to grow and occupy more width */
  margin-right: 16px; /* Optional: Add some space between the elements */
}

.flex-item:last-child {
  margin-right: 0; /* Remove the margin from the last element */
}
