@import '../../styles.variables.scss';

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  bottom: 0;
  position: fixed;

  font-size: small;
  color: $blue-evening;
  padding: 0.5rem 1.5rem;

  background-color: white;
  box-shadow: 0px -1px 8px 0px rgba($blue-evening, 0.1);

  z-index: 1;

  a {
    text-decoration: none;
  }

  &__license a {
    font-weight: 500;
    margin-left: 1.5rem;
  }

  &__support-container { 
    display: flex; 
    align-items: center; 
    gap: 0.5rem;
  }

  &__support {
    font-weight: 600;
  }

  &__docrequest{ 
    text-decoration: none;
    font-size: small;
    min-height: 0;
    font-weight: 600;
    border:0;
    cursor: pointer;
    color: #ff7321;
  }
}
