@import '~@wartsila/ui-kit/scss/index';
@import './styles.variables.scss';

:root {
  --color-text: #{$tkb-text-light};
  --color-background: #{$tkb-background-light};
  --color-tree-line: #{$blue-evening};
}

.dark {
  --color-text: #{$tkb-text-dark};
  --color-background: #{$tkb-background-dark};
  --color-tree-line: #{$tkb-background-light};
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  background-color: var(--color-background);
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
}

input[type='checkbox'],
input[type='radio'] {
  transform: scale(1.1);
  width: 1.2rem;
  height: 1.2rem;
}

a {
  color: $blue-noon;

  &:hover {
    text-decoration: none;
  }
}

.auth {
  &__screen {
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__error-title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__error-message {
    padding: 1rem;
    max-width: 600px;

    font-size: 0.872rem;
    background-color: $gray-10;
    border: 1px dashed $gray-20;
  }
}

.content {
  display: flex;
  margin: 0 auto;

  color: var(--color-text);
  padding: 2rem 1.5rem 4rem 1.5rem;

  &__sidebar {
    width: 300px;
    margin-right: 1rem;
    color: $tkb-text-light;
  }

  &__main {
    flex: 1;
    max-width: calc(100vw - 300px);
  }
}

.modal .modal-content {
  max-width: 800px;
}

.modal__content {
  padding: 1rem 3rem !important;
}

.modal__table {
  font-size: 14px;
  margin-top: 1rem;
  border: 1px solid $gray-20;

  & td:first-child,
  & th:first-child {
    border-right: 1px solid $gray-20;
  }

  & tbody tr:last-child td {
    border-bottom: none;
  }
}

.react-select__menu {
  color: #333;
}

.rdt_TableRow input[type=checkbox]:disabled {
  visibility: hidden;
}

.missing-link-request {
  text-align: center;
  padding-top: 3rem;

  &__button {
    white-space: nowrap;
    padding-top: 1rem;
    width: 50%;
    margin: 0 auto;
  }
}