@import '~@wartsila/ui-kit/scss/variables';

.subscriptions-modal .modal-content {
  max-width: 1280px;
  padding: 0.75rem;
}

.subscriptions {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__tabs {
    max-width: 90%;
    font-size: 0.9rem;
  }

  &__tab_inactive {
    color: $gray-30;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;

    button {
      flex-shrink: 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons > button {
    margin-right: 0.5rem;
  }

  &__new {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__name {
    width: 270px;
    margin-right: 0.5rem;
  }

  &__frequency {
    width: 150px;
  }

  &__body {
    min-height: 390px;
  }

  &__filters {
    gap: 2rem;
    display: grid;
    margin-bottom: 0.5rem;

    grid-template-columns: 1fr 1fr;

    .react-checkbox-tree ol {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .rct-title {
      white-space: nowrap;
    }
  }

  &__filters-title {
    padding: 0.5rem 0;
    font-weight: 500;
  }

  &__categories {
    display: flex;

    & > label {
      display: flex;
      align-items: center;
      padding-right: 1rem;

      span {
        padding-left: 0.5rem;
      }
    }
  }

  &__roles {
    user-select: none;
  }

  &__roles-input {
    display: flex;
    margin: 0.25rem 0;
    align-items: center;

    span {
      padding-top: 2px;
    }

    input {
      margin-right: 0.5rem;
    }
  }
}
