@import '../../styles.variables.scss';

.assignments {
  &__title {
    min-height: 1.5rem;
  }

  &__filters-wrapper {
    display: flex;
    padding: 0.5rem 0;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__filters {
    display: flex;
    z-index: 2;
  }

  &__filter {
    width: 276px;
    margin-right: 0.5rem;
  }

  &__table {
    padding: 0.75rem 0;
  }

  &__button {
    height: 38px;
  }

  &__cell-title {
    color: $gray-50;
    padding: 0.25rem 0;
  }

  &__loader {
    color: $info-color;
  }

  &__equipment-list {
    font-size: 0.8rem;
  }
}
